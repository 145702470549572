import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bop from "../images/gallery-images/p-1.jpg"
import ltc from "../images/gallery-images/p-5-2.jpg"
import pistolWeekend from "../images/gallery-images/p-17.jpg"
import privateInstruction from "../images/gallery-images/p-13.jpg"
const Courses = () => (
  <Layout>
    <SEO title="Firearms Courses Brazos County | Savage Outdoor & Defense" />
    <div class="p-8 bg-gray-800">
      <div class="max-w-full mx-auto">
        <div class="flex justify-center items-center content-center text-center">
          <div class="text-center ">
            <h1 class="text-3xl leading-9 tracking-tight font-bold text-blue-100 sm:text-4xl sm:leading-10 ">
              Certified Handgun Courses in Bryan-College Station{" "}
            </h1>
            <p class="mt-3 max-w-2xl mx-auto text-xl leading-7 text-blue-100 sm:mt-4">
              Private and group instruction for the{" "}
              <Link to="/texas-license-to-carry-ltc-course">
                <strong class="text-red-500">Texas License to Carry</strong>{" "}
                (LTC formerly known as CHL) Course, and the National Rifle
                Association's (NRA) Basics of Pistol Shooting.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey-50 m-4">
      <div class="relative max-w-screen-xl mx-auto">
        <div class="mt-6 grid gap-4 max-w-full mx-auto xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 xl:max-w-none">
          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover  transition duration-1000 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                src={ltc}
                alt="License to Carry (LTC) course in Bryan, College Station Texas. Brazos County"
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="">
                <span class="rounded text-blue-700 bg-blue-200 font-bold py-1 px-1 shadow-sm">
                  $65/person
                </span>
                <a
                  href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
                  class="block"
                >
                  <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                    Texas LTC Course (Client Favorite)
                  </h3>{" "}
                </a>
                <p class="mt-3 text-base leading-6 text-gray-500">
                  We offer the most inclusive Texas License to Carry course in
                  the Brazos Valley, and meet all state regulations to train you
                  in carrying a handgun.
                </p>
                <p class="mt-3 text-base leading-6 text-gray-500">
                  <strong>Women’s Class</strong>: We are proud to exclusively
                  host the first women’s only LTC course in the Brazos Valley.
                  Join us to meet fellow female shooters in your area!
                </p>
                <p class="mt-3 text-base leading-6 text-gray-500">
                  <strong>Couples’ Class</strong>: Are you looking for something
                  fun and exciting to do with your honey this weekend? Join us
                  for our Couples’ LTC class!
                </p>
              </div>
              <div class="mt-8 flex">
                <div class="inline-flex rounded-md shadow">
                  <a
                    href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
                    target="_blank"
                    class="inline-flex items-center justify-center px-3 py-1 border
                    border-transparent text-base leading-6 font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Register now
                  </a>
                </div>
                <div class="ml-3 inline-flex">
                  <a
                    href="/texas-license-to-carry-ltc-course"
                    class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex bg-white flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover transition duration-1000 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                src={bop}
                alt="Basics of Pistol shooting (BOP) course in Bryan, College Station, Texas. Brazos County"
              />
            </div>
            <div class="flex-1 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <span class="rounded text-blue-700 bg-blue-200 font-bold py-1 px-1 shadow-sm">
                  $140/person
                </span>
                <a href="/basics-of-pistol-shooting-bop" class="block">
                  <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                    NRA’s Basics of Pistol Shooting (BOP)
                  </h3>
                </a>
                <p class="mt-3 text-base leading-6 text-gray-500">
                  New to handguns? Looking to increase your experience level?
                  Come check out the NRA’s BOPS course. We offer the only NRA
                  supported BOPS course in the Brazos Valley!
                </p>
                <p class="mt-3 text-base leading-6 text-gray-500">
                  <strong>Youth Class</strong>: Looking to teach your kids
                  proper handling of handguns? Look no further than our Youth
                  BOPS Course! This course is for those aged 13-17.
                </p>
              </div>
              <div class="mt-8 flex">
                <div class="inline-flex rounded-md shadow">
                  <a
                    href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
                    target="_blank"
                    class="inline-flex items-center justify-center px-3 py-1 border
                    border-transparent text-base leading-6 font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Register now
                  </a>
                </div>
                <div class="ml-3 inline-flex">
                  <a
                    href="/basics-of-pistol-shooting-bop/"
                    class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover  transition duration-1000 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                src={pistolWeekend}
                alt="Intro to Pistols Weekend Course in Bryan, College Station, Tx. Brazos county"
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <span class="rounded text-blue-700 bg-blue-200 font-bold py-1 px-1 shadow-sm">
                  $175/person
                </span>
                <a href="/intro-to-pistols-weekend" class="block">
                  <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                    Intro to Pistols Weekend (Best Value)
                  </h3>
                </a>
                <p class="mt-3 text-base leading-6 text-gray-500">
                  Looking to expand your handgun knowledge as well as learn how
                  to defend yourself? Then look no further than our
                  all-inclusive Intro to Pistols Weekend package!
                </p>
              </div>
              <div class="mt-8 flex">
                <div class="inline-flex rounded-md shadow">
                  <a
                    href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
                    target="_blank"
                    class="inline-flex items-center justify-center px-3 py-1 border
                    border-transparent text-base leading-6 font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Register now
                  </a>
                </div>
                <div class="ml-3 inline-flex">
                  <a
                    href="/intro-to-pistols-weekend"
                    class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover  transition duration-1000 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                src={privateInstruction}
                alt="Private firearms instruction in Bryan, College Station, Tx. Brazos County"
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm leading-5 font-bold">
                  <ul>
                    <li>
                      <span class="text-blue-600">$40/hr</span> (1 person)
                    </li>
                    <li>
                      <span class="text-blue-600">$60/hr</span> (2 people)
                    </li>
                    <li>
                      <span class="text-blue-600">$75/hr</span> (3 people)
                    </li>
                  </ul>
                </p>
                <a
                  href="/private-and-group-general-firearm-instruction"
                  class="block"
                >
                  <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                    Private Instruction{" "}
                  </h3>
                </a>
                <p class="mt-3 text-base leading-6 text-gray-500">
                  For a lesson plan tailored specifically to your handgun or
                  your needs, book a private lesson today! We cover everything
                  from basics to self-defense.
                </p>
              </div>
              <div class="mt-8 flex">
                <div class="inline-flex rounded-md shadow">
                  <a
                    href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
                    target="_blank"
                    class="inline-flex items-center justify-center px-3 py-1 border
                    border-transparent text-base leading-6 font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Register now
                  </a>
                </div>
                <div class="ml-3 inline-flex">
                  <a
                    href="/private-and-group-general-firearm-instruction"
                    class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Courses
